/** /
// when main nav is sticky, hide logo-square
#logo-square {
    display: none;
}
@include media-breakpoint-up(xl) {
    #logo-main {
        opacity: 1;
        transition: all ease-in-out 150ms;
    }
    #logo-square {
        opacity: 0;
        transition: all ease-in-out 150ms;
    }

    .not-IE {
        #main-nav {
            #logo-main {
                opacity: 0;
                margin-left: 1rem;
            }
            #logo-square {
                display: inline-block;
                opacity: 1;
                margin-left: 1rem;
            }

            &.is-sticky {

                #logo-main {
                    margin-left: 0;
                    opacity: 1;
                }
                #logo-square {
                    margin-left: 0;
                    opacity: 0;
                }
            }
        }
    }
}
/**/

.nav-brand {
    position: relative;
    padding-right: 3rem;

    // white bg
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -1.5rem;
        right: -1rem;
        left: -1000%;
        box-sizing: content-box;
        height: 100%;
        background-color: white;
        padding: 1rem;
        transform: skewX(-15deg);
        transition: all .3s ease-in-out;
        z-index: -1;

        @media screen and (min-width: 992px) {
            top: -1rem;
        }
    }

    // slanted bar
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: -1.5rem;
        right: -2rem;
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        border-right: 30px solid var(--dcs-secondary);
        padding: 1rem;
        transform: skewX(-15deg);
        transition: all .3s ease-in-out;
        z-index: -1;

        @media screen and (min-width: 992px) {
            top: -1rem;
        }
    }

    &:hover {

        &:before {
            right: 2rem;
        }

        &:after {
            right: 1.5rem;
            border-right: 10px solid var(--dcs-secondary);
        }
    }
}


#main-nav {
    // background-color: rgba($white, 0.7);
    background-color: $primary;
    // top: -1px;
    // padding-top: 0.5rem;
    // padding-bottom: 0.5rem;
    // transition: padding 0.3s ease-in-out;

    // &:not(.is-sticky) {
    //     box-shadow: none !important;
    //     // padding-top: 1rem;
    //     // padding-bottom: 1rem;
    // }
    .nav-item{
        padding: 0;
    }
}


// #main-nav:before, 
.dropdown-menu:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
    z-index: -1;
    border-radius: 0.25rem;
}

@media screen and (min-width: 992px) and (max-width: 1100px) {

    #main-nav {
        .dropdown-toggle {
            &:after {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
.dropdown-divider{
    width: 90%;
    margin: auto;

}
.dropdown-menu{
    background-color:rgba($white, 0.7);
    padding: 0.5rem 0.5rem;
    
    li{
        padding: 3px 1px;
        position: relative;
    }
    
    .dropdown-submenu {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;

        @media screen and (min-width: 992px) {
            left: 50%;
            top: -7px;
        }
    }

    .dropdown-submenu-left {
        right: 100%;
        left: auto;
    }

    & > li:hover > .dropdown-submenu {
        display: block;
    }
}

.dropdown-item{
    padding: 0.2rem 1rem;
    border-top-left-radius: 50rem;
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
    border-bottom-left-radius: 50rem;

    &:hover{
        background-color: var(--dcs-primary);
        color: white;
        text-decoration: none;
    }
}

// .alert {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
// }

// svg.bi.bi-facebook.link-dark.text-decoration-underline{
//     transition: all 0.15s ease-in-out;
//     &:hover {
//         color: var(--dcs-gray-700);
//     }
// }