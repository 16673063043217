// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$variable-prefix: dcs-;

// Toggle global options
// $enable-gradients: true;
$enable-shadows: true;

// $breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");

// colors overrides styles
@import "overrides/colors";

// typography overrides styles
@import "overrides/typography";

// buttons overrides styles
@import "overrides/buttons";

// cards overrides styles
@import "overrides/cards";

// layout overrides styles
@import "overrides/layout";


// 3. Include remainder of REQUIRED Bootstrap stylesheets
// scss-docs-start import-stack
// Configuration
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// 4. Include any optional Bootstrap CSS as needed

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/tables";
// @import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
// @import "../node_modules/bootstrap/scss/pagination";
// @import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
// @import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
// @import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
// @import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/carousel";
// @import "../node_modules/bootstrap/scss/spinners";
// @import "../node_modules/bootstrap/scss/offcanvas";
// @import "../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

/**
 * locally hosted fonts
 */
@import "font-face";


// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import "../node_modules/bootstrap/scss/../../node_modules/bootstrap/scss/utilities/api";

// 6. Add additional custom code here

// // Create your own map
// $custom-theme-colors:(
//   "custom-color": #8bd0da,
// );

// $theme-colors: map-merge($theme-colors, $custom-theme-colors);
// $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// $utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
// $utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
// $utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

// // .bg classes not automatically generated. As an example, manually add .bg-custom-color
// :root{--bs-custom-color:#8bd0da;}
// .bg-custom-color{
//     background-color: var(--bs-custom-color);
// }


/**
 * main nav custom styles
 */
@import "main-nav";

/**
 * Cards custom styles
 */
@import "cards";

/**
 * All common custom styles
 */
 @import "common";
/**
 * footer custom styles
 */
@import "footer";

/**
 * tab custom styles
 */
 @import "tabs";

